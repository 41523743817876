import React from 'react';
import { TextArea } from '../../components/common/TextArea';
import mixpanel from 'mixpanel-browser';
import { useSelector } from 'react-redux';

export const AddIdea = ({ newBlogPost, setNewBlogPost }) => {
  const userId = useSelector((st) => st.auth?.user?.userId);

  return (
    <div className="w-full h-[225px]">
      <TextArea
        placeholder="Type your idea here"
        className="w-full !h-full"
        value={newBlogPost}
        onBlur={(event) => {
          if (event.target.value) {
            mixpanel.track('New Session: Idea Updated', {
              distinct_id: userId,
            });
          }
        }}
        onChange={setNewBlogPost}
      />
    </div>
  );
};
