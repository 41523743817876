import { useContext } from 'react';
import { WorkspaceContext } from '../App';

export const useWorkspacePlan = () => {
  const { workspace, workspacePlanCache, isLoading } =
    useContext(WorkspaceContext);

  if (!workspace.workspaceId) {
    return {};
  }

  return {
    isLoading,
    isAppSumo:
      workspacePlanCache.vendor !== undefined &&
      workspacePlanCache.vendor === 'App Sumo',
    ...workspacePlanCache,
  };
};
