const moment = require('moment');

const PLANS = {
  solo: {
    id: 'solo',
    name: 'Solo',
    cost: 0,
    order: 1,
    maxAttendees: 100,
    maxMinutes: 60,
    recording: false,
    streaming: false,
    maxContentKit: 1,
  },
  free: {
    id: 'free',
    name: 'Free',
    cost: 0,
    order: 1,
    maxAttendees: 100,
    maxMinutes: 30,
    recording: false,
    streaming: false,
    maxContentKit: 1,
  },
  team: {
    id: 'team',
    name: 'Team',
    cost: 25,
    order: 2,
    maxAttendees: 50,
    maxMinutes: 90,
    recording: true,
    streaming: false,
  },
  event: {
    id: 'event',
    name: 'Event',
    cost: 200,
    order: 3,
    maxAttendees: 500,
    maxMinutes: 300,
    recording: true,
    streaming: true,
  },
  starter: {
    id: 'starter',
    name: 'Starter',
    cost: 139,
    order: 3,
    maxAttendees: 100,
    maxMinutes: 120,
    recording: true,
    streaming: true,
    maxContentKit: 6,
  },
  starter_annual: {
    id: 'starter_annual',
    name: 'Starter Annual',
    cost: 1188,
    order: 3,
    maxAttendees: 100,
    maxMinutes: 300,
    recording: true,
    streaming: true,
    maxContentKit: 6,
  },
  business: {
    id: 'business',
    name: 'Business',
    cost: 599,
    order: 3,
    maxAttendees: 500,
    maxMinutes: 120,
    recording: true,
    streaming: true,
    maxContentKit: 12,
  },
  business_annual: {
    id: 'business_annual',
    name: 'Business',
    cost: 499 * 12,
    order: 3,
    maxAttendees: 500,
    maxMinutes: 120,
    recording: true,
    streaming: true,
    maxContentKit: 12,
  },
  professional: {
    id: 'professional',
    name: 'Professional',
    cost: 499,
    order: 3,
    maxAttendees: 500,
    maxMinutes: 300,
    recording: true,
    streaming: true,
    maxContentKit: 12,
  },
  enterprise: {
    id: 'enterprise',
    name: 'Enterprise',
    cost: 200,
    order: 3,
    maxAttendees: 500,
    maxMinutes: 300,
    recording: true,
    streaming: true,
    maxContentKit: 6,
  },
  admin: {
    id: 'admin',
    name: 'Admin',
    cost: 499,
    order: 3,
    maxAttendees: 500,
    maxMinutes: 120,
    recording: true,
    streaming: true,
    maxContentKit: 12,
  },
  pilot: {
    id: 'pilot',
    name: 'Pilot',
    cost: 499,
    order: 3,
    maxAttendees: 500,
    maxMinutes: 300,
    recording: true,
    streaming: true,
    maxContentKit: 12,
  },
  trial: {
    id: 'trial',
    name: 'Trial',
    cost: 0,
    order: 3,
    maxAttendees: 500,
    maxMinutes: 30,
    recording: true,
    streaming: true,
    maxContentKit: 12,
  },
};

const planEnforcementTypes = {
  MEETING_FULL: 'MEETING_FULL',
  RECORDING_NOT_SUPPORTED: 'RECORDING_NOT_SUPPORTED',
  STREAMING_NOT_SUPPORTED: 'STREAMING_NOT_SUPPORTED',
  TIME_EXPIRING: 'TIME_EXPIRING',
};

const enforcementMessagesReceivers = {
  SUPER_ADMIN: 'SUPER_ADMIN',
  ADMIN: 'ADMIN',
  MODERATOR: 'MODERATOR',
  GUEST: 'GUEST',
};

const isValidTrial = (dateString) => {
  if (!dateString) {
    return false;
  }

  const date = new Date(dateString);
  const now = new Date();

  return moment(date).isAfter(moment(now));
};

const SOLO_PLAN = PLANS.solo.id;
const TRIAL_PLAN = PLANS.starter.id;
const ADMIN_PLAN = PLANS.admin.id;
const PILOT_PLAN = PLANS.pilot.id;
const BUSINESS_PLAN = PLANS.business.id;

const checkIsSubscriptionPastDue = (subscription) => {
  if (!subscription) {
    return false;
  }

  return subscription.status === 'past_due' || subscription.status === 'unpaid';
};

const checkIsCanceledBeforeBilling = (subscription) => {
  if (!subscription) {
    return false;
  }

  if (!subscription.cancel_at_period_end) {
    return true;
  }

  const isActive =
    subscription.status === 'active' || subscription.status === 'trialing';
  const isCanceled = !!subscription.canceled_at;
  const isInBilling = Date.now() < subscription.cancel_at * 1000; // Stripe returns unix timestamp, which is not in miliseconds

  return isActive && isCanceled && isInBilling;
};

const getSubscriptionPlan = (subscription, workspace) => {
  const isValidDate = isValidTrial(workspace?.workspacePlanValidUntil);

  if (
    workspace?.workspacePlan === ADMIN_PLAN ||
    workspace?.workspacePlan === PILOT_PLAN ||
    workspace?.workspacePlan === BUSINESS_PLAN ||
    workspace?.workspacePlan === SOLO_PLAN
  ) {
    if (isValidDate) {
      return {
        plan: workspace?.workspacePlan,
        isTrial:
          workspace?.workspacePlan === SOLO_PLAN &&
          ((!subscription &&
            workspace?.isTrial &&
            !workspace?.activeSubscription) ||
            (!workspace?.activeSubscription &&
              isValidTrial(workspace?.workspacePlanValidUntil))),
      };
    }
  }

  if (!subscription && workspace?.workspacePlan === TRIAL_PLAN) {
    if (isValidDate) {
      return {
        plan: workspace?.workspacePlan,
        isTrial: !subscription,
      };
    }
  }

  const isOnTrial = isValidTrial(workspace?.workspacePlanValidUntil);

  if (isOnTrial && !subscription) {
    return { plan: TRIAL_PLAN, isTrial: true };
  }

  if (!subscription) {
    return { plan: PLANS.free.id, isTrial: false };
  }

  const firstSubscriptionItem = subscription.items.data[0];

  if (!firstSubscriptionItem || checkIsSubscriptionPastDue(subscription)) {
    return { plan: PLANS.free.id, isTrial: false };
  }

  const currentPlan = firstSubscriptionItem.price.lookup_key;

  if (isOnTrial && currentPlan === PLANS.free.id) {
    return { plan: TRIAL_PLAN, isTrial: true };
  }

  return { plan: currentPlan, isTrial: false };
};

const getTrialDateUntil = (workspace) => {
  if (!workspace) {
    return null;
  }

  return workspace.workspacePlanValidUntil;
};

const getCurrentMeetingPlan = (workspace) => {
  if (!workspace) return PLANS.free.id;

  const { workspacePlanValidUntil, activeSubscription, workspacePlan } =
    workspace || {};
  const isTrial = isValidTrial(workspacePlanValidUntil);

  if (workspacePlan === PLANS.admin.id) return workspacePlan;

  if (activeSubscription) {
    return workspacePlan;
  }

  if (isTrial) {
    return PLANS.trial.id;
  }

  return PLANS.free.id;
};

module.exports = {
  PLANS,
  planEnforcementTypes,
  enforcementMessagesReceivers,
  getSubscriptionPlan,
  getTrialDateUntil,
  checkIsSubscriptionPastDue,
  checkIsCanceledBeforeBilling,
  isValidTrial,
  getCurrentMeetingPlan,
};
