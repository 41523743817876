import { useCallback, useEffect, useState } from 'react';
import {
  fetchYoutubeChannelsApi,
  refreshGoogleOAuthAccessTokenApi,
  revokeOAuthTokenApi,
  updateCustomerEngagementStagesApi,
  updateWorkspaceApi,
} from '../helper/api';
import { useSelector } from 'react-redux';
import zyncCustomerEngagementStages from 'zync-common/hubspot/zyncCustomerEngagementStages';

export const useYoutubeIntegration = ({
  workspace,
  onWorkspaceUpdated,
  refreshSeries,
  localDispatch,
}) => {
  const userId = useSelector((st) => st.auth?.user?.userId);
  const { integrations, workspaceId } = workspace || {};
  const { youtube } = integrations || {};
  const {
    oAuthAccessToken = '',
    oAuthRefreshToken = '',
    timestamp,
  } = youtube || {};
  const hasOAuthAccessToken = oAuthAccessToken?.length > 0;

  const [error, setError] = useState(null);
  const [channels, setChannels] = useState([]);
  const [showNoChannelsModal, setShowNoChannelsModal] = useState(false);

  const revokeOAuthToken = useCallback(async () => {
    if (!hasOAuthAccessToken) return;

    await revokeOAuthTokenApi(oAuthAccessToken);
    const { error: updateError, result } = await updateWorkspaceApi(
      workspaceId,
      { youtube: { oAuthAccessToken: '', oAuthRefreshToken: '' } }
    );
    if (updateError) return;
    if (onWorkspaceUpdated) {
      onWorkspaceUpdated(result.workspace);
    }
  }, [hasOAuthAccessToken, oAuthAccessToken, workspaceId, onWorkspaceUpdated]);

  const refreshOAuthToken = useCallback(async () => {
    if (!oAuthRefreshToken) return;

    const { error } = await refreshGoogleOAuthAccessTokenApi(
      oAuthRefreshToken,
      workspaceId
    );

    return error ? false : true;
  }, [oAuthRefreshToken, workspaceId]);

  useEffect(() => {
    if (!hasOAuthAccessToken || !workspaceId) return;
    updateCustomerEngagementStagesApi(
      userId,
      zyncCustomerEngagementStages.CONNECTED_YOUTUBE.stage
    );
  }, [hasOAuthAccessToken, userId, workspaceId]);

  useEffect(() => {
    if (!hasOAuthAccessToken || !workspaceId) return;

    const getYoutubeChannels = async () => {
      const { items = [], error } = await fetchYoutubeChannelsApi(
        oAuthAccessToken,
        workspaceId
      );

      if (error) {
        if (oAuthRefreshToken) {
          await refreshGoogleOAuthAccessTokenApi(
            oAuthRefreshToken,
            workspaceId
          );
          if (onWorkspaceUpdated) {
            onWorkspaceUpdated();
          }
          if (refreshSeries) {
            await refreshSeries(undefined, (series) => {
              if (localDispatch) {
                localDispatch({
                  type: 'REFRESH_SERIES',
                  series,
                });
              }
            });
          }
          return;
        } else {
          const { error: updateError, result } = await updateWorkspaceApi(
            workspaceId,
            { youtube: { oAuthAccessToken: '', oAuthRefreshToken: '' } }
          );
          if (updateError) return;
          if (onWorkspaceUpdated) {
            onWorkspaceUpdated(result.workspace);
          }
          setError(
            'Error retrieving youtube channels, please sign in and try again.'
          );
          return;
        }
      }

      setChannels(items);

      if (items.length === 0) {
        setShowNoChannelsModal(true);
      }
    };

    getYoutubeChannels();
  }, [
    hasOAuthAccessToken,
    localDispatch,
    oAuthAccessToken,
    oAuthRefreshToken,
    onWorkspaceUpdated,
    refreshSeries,
    workspaceId,
  ]);

  return {
    error,
    channels,
    hasOAuthAccessToken,
    oAuthAccessToken,
    revokeOAuthToken,
    timestamp,
    refreshOAuthToken,
    showNoChannelsModal,
    setShowNoChannelsModal,
  };
};
