import { noop } from 'lodash';
import React, { createContext } from 'react';
import { useChunkedLocalRecording } from '../../../hooks/useChunkedLocalRecording';
import { useMemo } from 'react';

export const ChunkedLocalRecordingContext = createContext({
  /** Starts local recording */
  startLocalRecording: noop,

  /** Stops local recording and uploads to MUX */
  stopLocalRecording: noop,

  /** Boolean if currently local recording */
  isLocalRecording: false,

  /** Boolean if mic is on and has received audio during local recording */
  receivedAudio: null,
});

const ChunkedLocalRecordingContextProvider = ({
  children,
  meetingSeriesId,
  workspaceId,
  isSoloEpisode,
}) => {
  const {
    startRecording: startLocalRecording,
    stopRecording: stopLocalRecording,
    isLocalRecording,
    receivedAudio,
  } = useChunkedLocalRecording({ meetingSeriesId, workspaceId, isSoloEpisode });

  const value = useMemo(
    () => ({
      startLocalRecording,
      stopLocalRecording,
      isLocalRecording,
      receivedAudio,
    }),
    [isLocalRecording, receivedAudio, startLocalRecording, stopLocalRecording]
  );

  return (
    <ChunkedLocalRecordingContext.Provider value={value}>
      {children}
    </ChunkedLocalRecordingContext.Provider>
  );
};

export default ChunkedLocalRecordingContextProvider;
