import React from 'react';
import { TextArea } from '../../components/common/TextArea';
import mixpanel from 'mixpanel-browser';
import { useSelector } from 'react-redux';

export const AddBlogPost = ({ newBlogPost, setNewBlogPost, onboarding }) => {
  const userId = useSelector((st) => st.auth?.user?.userId);

  return (
    <div className="w-full h-[225px]">
      <TextArea
        placeholder="Paste the text from your recent LinkedIn Post, Blog or Newsletter Article"
        className="w-full !h-[80%]"
        value={newBlogPost}
        onChange={setNewBlogPost}
        onBlur={(event) => {
          if (event.target.value) {
            mixpanel.track(
              onboarding
                ? 'Onboarding: Updated Content Style'
                : 'New Session: Content Updated',
              {
                distinct_id: userId,
              }
            );
          }
        }}
        label={
          <p className="text-center mb-4">
            Please paste content of entire Blog Post / LinkedIn Post /
            Newsletter
          </p>
        }
      />
    </div>
  );
};
