import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useYoutubeIntegration } from '../../../hooks/useYoutubeIntegration';
import axios from 'axios';
import { PROCESSOR_BASE_URL } from '../../../helper/constants';
import { delay } from '../../../helper/exponentialFetchRetry';
import { getStaticAssetUrl } from '../../../helper/getStaticAssetUrl';
import { CheckMarkIcon } from '../../../components/icons/CheckMarkIcon';
import { UploadToYoutubeModal } from './UploadToYoutubeModal';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import classNames from '../../../helper/classNames';
import { updateCustomerEngagementStagesApi } from '../../../helper/api';
import zyncCustomerEngagementStages from 'zync-common/hubspot/zyncCustomerEngagementStages';
import { useSelector } from 'react-redux';
import mixpanel from 'mixpanel-browser';
import { getCurrentMeetingPlan } from 'zync-common/zyncCustomerPlans';
import { useTrackActivity } from '../../../hooks/track';

const youtubeLogo = getStaticAssetUrl('youtube_logo.png');

export function scrollToElement(elementId) {
  const targetElement = document.getElementById(elementId);

  if (targetElement) {
    const yOffset = targetElement.getBoundingClientRect().top - 250;
    window.scrollTo({ top: yOffset, behavior: 'smooth' });
  }
}

export const formatDuration = (duration) => {
  const hours = Math.floor(duration / 3600);
  const minutes = Math.floor((duration % 3600) / 60);
  const remainingSeconds = Math.floor(duration % 60);

  const formattedHours = String(hours).padStart(2, '0');
  const formattedMinutes = String(minutes).padStart(2, '0');
  const formattedSeconds = String(remainingSeconds).padStart(2, '0');

  if (hours > 0) {
    return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
  } else {
    return `${formattedMinutes}:${formattedSeconds}`;
  }
};

export const getHighestQualityDownloadUrl = (files) => {
  if (files.length === 0) return null;
  const result = [...files];
  const getRank = (name) => {
    switch (name) {
      case 'high.mp4':
        return 1;
      case 'medium.mp4':
        return 2;
      case 'low.mp4':
        return 3;
      default:
        return 4;
    }
  };
  result.sort((f1, f2) => getRank(f1.name) - getRank(f2.name));
  return result[0].downloadUrl;
};

const YoutubePost = ({
  playbackUrl,
  title,
  thumbnailUrl,
  thumbnailTitle,
  description,
  files,
  canUploadToYoutube = false,
  handlePublish,
  highlightId,
  uploadedToYoutube,
  saveHighlightSocialMediaPost,
  saveShortsSocialMediaPost,
  aspectRatio = 'landscape',
  createdByProcessorId,
  alternateVideoUrl = '',
  posterUrl,
  brandKit,
  downloadUrl,
  videoUrlOverride,
  shared,
  postTitle,
  workspacePlan,
}) => {
  const { primaryColor } = brandKit || {};
  const [showPublishModal, setShowPublishModal] = useState(false);
  const [videoDuration, setVideoDuration] = useState(null);
  const videoDownloadUrl = getHighestQualityDownloadUrl(files);
  const videoGCSUrl = downloadUrl;
  const [showVideo, setShowVideo] = useState(true);
  const videoRef = useRef(null);

  const handleLoadedMetadata = () => {
    if (videoRef.current) {
      setVideoDuration(videoRef.current.duration);
      setShowVideo(false);
    }
  };

  const track = useTrackActivity();

  useEffect(() => {
    track('Content Kit: YouTube Post Opened', { groupId: highlightId });
  }, [highlightId, track]);

  return (
    <>
      {showVideo && (
        <video
          ref={videoRef}
          src={alternateVideoUrl || videoDownloadUrl}
          className="hidden"
          onLoadedMetadata={handleLoadedMetadata}
          onError={() => {
            setShowVideo(false);
          }}
        />
      )}
      {showPublishModal && (
        <UploadToYoutubeModal
          highlightId={highlightId}
          title={title}
          thumbnailTitle={thumbnailTitle}
          description={description}
          createdByProcessorId={createdByProcessorId}
          handlePublish={handlePublish}
          setShowPublishModal={setShowPublishModal}
          thumbnailUrl={thumbnailUrl}
          uploadedToYoutube={uploadedToYoutube}
          playbackUrl={playbackUrl}
          alternateVideoUrl={alternateVideoUrl}
          aspectRatio={aspectRatio}
          posterUrl={posterUrl}
          canUploadToYoutube={canUploadToYoutube}
          saveHighlightSocialMediaPost={saveHighlightSocialMediaPost}
          saveShortsSocialMediaPost={saveShortsSocialMediaPost}
          videoDownloadUrl={videoDownloadUrl}
          videoGCSUrl={videoGCSUrl}
          shared={shared}
          postTitle={postTitle}
          workspacePlan={workspacePlan}
        />
      )}
      <div
        className={classNames(
          'relative',
          aspectRatio === 'portrait' ? 'w-[225px]' : 'w-[320px]'
        )}
      >
        <button
          className={classNames(
            'flex flex-col gap-3',
            !videoUrlOverride
              ? aspectRatio === 'portrait'
                ? 'max-w-[225px]'
                : 'max-w-[320px]'
              : 'w-full'
          )}
          onClick={() => {
            mixpanel.track('Content Kit: Youtube: Modal Opened', {
              highlightId,
              aspectRatio,
            });
            setShowPublishModal(true);
          }}
        >
          <div
            className={classNames(
              'w-full relative flex items-center',
              aspectRatio === 'portrait' && 'h-[400px] bg-white rounded-lg'
            )}
            style={{
              backgroundColor: videoUrlOverride ? 'transparent' : primaryColor,
            }}
          >
            {uploadedToYoutube && (
              <div className="flex min-w-full min-h-full absolute top-0 left-0 bg-black/50"></div>
            )}
            <img
              src={thumbnailUrl}
              alt=""
              className={classNames(
                'object-contain',
                aspectRatio !== 'portrait' && 'rounded-lg'
              )}
            />
            <div className="flex gap-1 absolute bottom-1.5 left-1.5 text-white text-xs font-medium">
              {videoDuration && (
                <div className="flex px-1.5 bg-purple rounded items-center justify-center">
                  {formatDuration(videoDuration)}
                </div>
              )}
              {uploadedToYoutube && (
                <div className="flex px-1.5 bg-purple rounded items-center justify-center gap-1">
                  <div className="flex justify-center items-center w-2 h-2 rounded border-white border">
                    <CheckMarkIcon className="fill-white" />
                  </div>
                  <span>PUBLISHED</span>
                </div>
              )}
            </div>
          </div>
          <div className="text-blue-dark font-semibold text-left">
            {thumbnailTitle || title}
          </div>
        </button>
        <video
          className={classNames('absolute inset-0 w-[225px] -z-10')}
          width={225}
          src={videoUrlOverride}
        />
      </div>
    </>
  );
};

export const YoutubePosts = ({
  highlights,
  recording,
  youtubeDescription,
  workspace,
  refreshContentKit,
  saveHighlightSocialMediaPost,
  saveShortsSocialMediaPost,
  teaserEditedLandscape,
  teaserEditedPortrait,
  episodeSettingsEpisodeVideoUrl,
  refreshSeries,
  localDispatch,
  posterUrl,
  shared,
  shorts,
  episodeTitle,
  isSoloEpisode = false,
}) => {
  const userId = useSelector((st) => st.auth?.user?.userId);
  const { meetingSeriesId, section } = useParams();
  const { brandKit } = workspace || {};
  const { hasOAuthAccessToken, channels, timestamp, refreshOAuthToken } =
    useYoutubeIntegration({
      workspace,
      refreshSeries,
      localDispatch,
    });
  const workspacePlan = getCurrentMeetingPlan(workspace);

  const track = useTrackActivity();

  useEffect(() => {
    track('Content Kit: YouTube Section Loaded');
  }, [track]);

  const {
    playbackUrl: teaserLandscapePlaybackUrl,
    files: teaserLandscapeFiles,
    uploadedToYoutube: uploadedTeaserLandscapeToYoutube,
  } = teaserEditedLandscape || {};

  const {
    playbackUrl: teaserPortraitPlaybackUrl,
    files: teaserPortraitFiles,
    uploadedToYoutube: uploadedTeaserPortraitToYoutube,
  } = teaserEditedPortrait || {};

  const canUploadToYoutube =
    workspace && hasOAuthAccessToken && channels.length > 0;

  const youtubeHighlights = useMemo(
    () =>
      highlights
        .map((h) => {
          if (h.HIGHLIGHT_EDITED_LANDSCAPE) {
            return {
              highlightId: h.highlightId,
              title: h.title,
              thumbnailUrl: h.HIGHLIGHT_THUMBNAIL.imageUrl,
              playbackUrl: h.HIGHLIGHT_EDITED_LANDSCAPE.playbackUrl,
              files: h.HIGHLIGHT_EDITED_LANDSCAPE?.files || [],
              uploadedToYoutube: h?.uploadedToYoutube,
              description:
                h.HIGHLIGHT_SOCIAL_MEDIA_POSTS.socialMediaPosts?.youtube?.text,
              downloadUrl:
                h.HIGHLIGHT_EDITED_LANDSCAPE?.downloadUrl || h?.downloadUrl,
              postTitle:
                h.HIGHLIGHT_SOCIAL_MEDIA_POSTS.socialMediaPosts?.youtube
                  ?.title || h.title,
            };
          } else {
            if (isSoloEpisode) {
              return null;
            }
            return {
              highlightId: h.highlightId,
              title: h.title,
              thumbnailUrl: h.HIGHLIGHT_THUMBNAIL.imageUrl,
              playbackUrl: h.HIGHLIGHT_RAW_LANDSCAPE.playbackUrl,
              files: h.HIGHLIGHT_RAW_LANDSCAPE.files,
              uploadedToYoutube: h?.uploadedToYoutube,
              description:
                h.HIGHLIGHT_SOCIAL_MEDIA_POSTS.socialMediaPosts?.youtube?.text,
              postTitle:
                h.HIGHLIGHT_SOCIAL_MEDIA_POSTS.socialMediaPosts?.youtube
                  ?.title || h.title,
            };
          }
        })
        .filter((h) => !!h),
    [highlights, isSoloEpisode]
  );

  const youtubePortraitHighlights = useMemo(
    () =>
      isSoloEpisode
        ? highlights
            .filter((h) => {
              if (!h?.HIGHLIGHT_EDITED_PORTRAIT) return false;
              if (h?.duration) {
                return h?.duration < 60;
              }

              if (h?.startTime && h?.endTime) {
                return h?.endTime - h?.startTime < 60;
              }

              return false;
            })
            .map((h) => ({
              highlightId: h.highlightId,
              title: h.title,
              thumbnailUrl: h?.HIGHLIGHT_PORTRAIT_THUMBNAIL?.imageUrl,
              playbackUrl: h?.HIGHLIGHT_EDITED_PORTRAIT?.playbackUrl,
              description:
                h.HIGHLIGHT_SOCIAL_MEDIA_POSTS.socialMediaPosts.youtube.text,
              downloadUrl: h?.HIGHLIGHT_EDITED_PORTRAIT?.downloadUrl,
              uploadedToYoutube: h?.uploadedToYoutube,
              aspectRatio: 'portrait',
              postTitle:
                h.HIGHLIGHT_SOCIAL_MEDIA_POSTS.socialMediaPosts?.youtube
                  ?.title || h.title,
            }))
        : [],
    [isSoloEpisode, highlights]
  );

  const youtubeShorts = useMemo(
    () =>
      shorts
        .filter((s) => s.INTERESTING_MOMENTS_SHORT_MIXED_EDITED_PORTRAIT)
        .map((s, index) => ({
          highlightId: s.highlightId,
          title: s.shortTextData?.youtubeShortTitle || `Shorts ${index + 1}`,
          description: s.shortTextData?.youtubeShortDescription || '',
          thumbnailTitle: s.shortTextData?.thumbnailTitle || '',
          playbackUrl:
            s.INTERESTING_MOMENTS_SHORT_MIXED_EDITED_PORTRAIT.playbackUrl,
          downloadUrl:
            s.INTERESTING_MOMENTS_SHORT_MIXED_EDITED_PORTRAIT.playbackUrl,
          uploadedToYoutube: false,
          aspectRatio:
            s.INTERESTING_MOMENTS_SHORT_MIXED_EDITED_PORTRAIT.aspectRatio,
        })),
    [shorts]
  );

  const {
    playbackUrl: recordingPlaybackUrl,
    thumbnailUrl: recordingThumbnailUrl,
    files: recordingFiles,
    uploadedToYoutube: uploadedRecordingToYoutube,
  } = recording || {};

  const handlePublish = useCallback(
    async (groupId, createdByProcessorId, title, description, visibility) => {
      if (Date.now() - timestamp >= 3_540_000) {
        await refreshOAuthToken();
        await refreshSeries(undefined, (series) =>
          localDispatch({
            type: 'REFRESH_SERIES',
            series,
          })
        );
      }

      const taskDetails = {
        meetingSeriesId,
        workspaceId: workspace?.workspaceId,
        processorId: 'UPLOAD_TO_YOUTUBE',
      };

      const payload = {
        createdByProcessorId,
        title,
        description,
        visibility,
      };

      if (groupId) {
        payload.groupId = groupId;
      }

      const body = {
        taskDetails,
        payload,
        forceRun: true,
        useOptionals: true,
      };

      axios.post(`${PROCESSOR_BASE_URL}/cloudtask`, body);

      updateCustomerEngagementStagesApi(
        userId,
        zyncCustomerEngagementStages.PUBLISHED_SOCIAL.stage
      );

      await delay(30_000);

      await refreshContentKit();
    },
    [
      localDispatch,
      meetingSeriesId,
      refreshContentKit,
      refreshOAuthToken,
      refreshSeries,
      timestamp,
      userId,
      workspace?.workspaceId,
    ]
  );

  useEffect(() => {
    if (!section) return;
    scrollToElement(section);
  }, [section]);

  useEffect(() => {
    if (!meetingSeriesId) return;
    mixpanel.track('Content Kit: Youtube Section Click', {
      meetingSeriesId,
    });
  }, [meetingSeriesId]);

  return (
    <>
      <Link
        to={
          shared
            ? `/e/${meetingSeriesId}/contentkit`
            : `/e/${meetingSeriesId}/details/publish`
        }
        className="self-start pl-64"
      >
        <div className="font-medium text-sm">
          <span className="text-[#97A0AF]">
            Content Kit /{'  '}
            <span className="text-blue-dark">YouTube Highlights</span>
          </span>
        </div>
      </Link>
      <div className="flex py-10 pl-64 pr-52 bg-[#FF0000]/5 w-full flex-col gap-5">
        <img
          src={youtubeLogo}
          alt="youtube logo"
          className="object-contain w-[120px]"
        />
        <div className="flex flex-col gap-[30px]">
          {!isSoloEpisode && (
            <div className="flex flex-col gap-5" id="fullVideo">
              <div className="text-blue-dark font-semibold text-xl">
                Full Episode
              </div>
              <YoutubePost
                playbackUrl={recordingPlaybackUrl}
                thumbnailUrl={recordingThumbnailUrl}
                title={episodeTitle || 'Full Episode'}
                files={recordingFiles}
                canUploadToYoutube={canUploadToYoutube}
                handlePublish={handlePublish}
                description={youtubeDescription}
                uploadedToYoutube={uploadedRecordingToYoutube}
                createdByProcessorId="TRIMMED_RECORDING"
                posterUrl={posterUrl}
                saveHighlightSocialMediaPost={saveHighlightSocialMediaPost}
                alternateVideoUrl={episodeSettingsEpisodeVideoUrl}
                brandKit={brandKit}
                shared={shared}
                workspacePlan={workspacePlan}
              />
            </div>
          )}

          {(youtubeHighlights || teaserEditedLandscape) && (
            <div className="flex flex-col gap-5" id="highlights">
              <div className="text-blue-dark font-semibold text-xl">
                Video Clips
              </div>
              <div className="flex gap-[30px] flex-wrap">
                {youtubeHighlights.map((h, index) => (
                  <YoutubePost
                    key={h.highlightId}
                    highlightId={h.highlightId}
                    playbackUrl={h.playbackUrl}
                    thumbnailUrl={h.thumbnailUrl}
                    title={h.title}
                    files={h.files}
                    canUploadToYoutube={canUploadToYoutube}
                    handlePublish={handlePublish}
                    description={h.description}
                    uploadedToYoutube={h.uploadedToYoutube}
                    createdByProcessorId="HIGHLIGHT_EDITED_LANDSCAPE"
                    posterUrl={posterUrl}
                    saveHighlightSocialMediaPost={saveHighlightSocialMediaPost}
                    brandKit={brandKit}
                    shared={shared}
                    downloadUrl={h?.downloadUrl}
                    postTitle={h?.postTitle}
                    workspacePlan={workspacePlan}
                  />
                ))}
                {teaserEditedLandscape && (
                  <YoutubePost
                    playbackUrl={teaserLandscapePlaybackUrl}
                    thumbnailUrl={posterUrl}
                    title="Teaser Video (Landscape)"
                    files={teaserLandscapeFiles}
                    canUploadToYoutube={canUploadToYoutube}
                    handlePublish={handlePublish}
                    uploadedToYoutube={uploadedTeaserLandscapeToYoutube}
                    createdByProcessorId="TEASER_EDITED_LANDSCAPE"
                    posterUrl={posterUrl}
                    saveHighlightSocialMediaPost={saveHighlightSocialMediaPost}
                    brandKit={brandKit}
                    shared={shared}
                    workspacePlan={workspacePlan}
                  />
                )}
              </div>
            </div>
          )}
          {(youtubeShorts.length > 0 ||
            teaserEditedPortrait ||
            (isSoloEpisode && youtubePortraitHighlights.length > 0)) && (
            <div className="flex flex-col gap-5" id="shorts">
              <div className="text-blue-dark font-semibold text-xl">Shorts</div>
              <div className="flex gap-[30px] flex-wrap">
                {teaserEditedPortrait && (
                  <YoutubePost
                    playbackUrl={teaserPortraitPlaybackUrl}
                    thumbnailUrl={posterUrl}
                    title="Teaser Video (Portrait)"
                    files={teaserPortraitFiles}
                    canUploadToYoutube={canUploadToYoutube}
                    handlePublish={handlePublish}
                    uploadedToYoutube={uploadedTeaserPortraitToYoutube}
                    createdByProcessorId="TEASER_EDITED_PORTRAIT"
                    posterUrl={posterUrl}
                    aspectRatio="portrait"
                    brandKit={brandKit}
                    shared={shared}
                    workspacePlan={workspacePlan}
                  />
                )}
                {youtubeShorts.length > 0 &&
                  youtubeShorts.map((s) => (
                    <YoutubePost
                      playbackUrl={s.playbackUrl}
                      thumbnailUrl={s.thumbnailUrl}
                      title={s.title}
                      description={s.description}
                      canUploadToYoutube={false}
                      handlePublish={handlePublish}
                      uploadedToYoutube={s.uploadedToYoutube}
                      posterUrl={posterUrl}
                      aspectRatio={s.aspectRatio}
                      brandKit={brandKit}
                      thumbnailTitle={s.thumbnailTitle}
                      key={s.highlightId}
                      files={[]}
                      downloadUrl={s.downloadUrl}
                      videoUrlOverride={s.downloadUrl}
                      saveShortsSocialMediaPost={saveShortsSocialMediaPost}
                      highlightId={s.highlightId}
                      shared={shared}
                      workspacePlan={workspacePlan}
                    />
                  ))}
                {youtubePortraitHighlights.map((h) => (
                  <YoutubePost
                    playbackUrl={h.playbackUrl}
                    thumbnailUrl={h.thumbnailUrl}
                    title={h.title}
                    description={h.description}
                    posterUrl={posterUrl}
                    aspectRatio={h.aspectRatio}
                    brandKit={brandKit}
                    key={h.highlightId}
                    files={[]}
                    downloadUrl={h.downloadUrl}
                    videoUrlOverride={h.thumbnailUrl ? null : h.downloadUrl}
                    saveHighlightSocialMediaPost={saveHighlightSocialMediaPost}
                    highlightId={h.highlightId}
                    shared={shared}
                    canUploadToYoutube={false}
                    handlePublish={handlePublish}
                    uploadedToYoutube={h.uploadedToYoutube}
                    postTitle={h?.postTitle}
                    workspacePlan={workspacePlan}
                  />
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};
