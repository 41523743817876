import React, { useEffect, useRef, useState } from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { isMobile, makeRandomId } from '../helper';
import { useUserWorkspaces } from '../hooks/useUserWorkspaces';
import classNames from '../helper/classNames';
import { fromError, logerror } from '../helper/contextualLogger';
import { LogoSpinnerFullCentered } from '../components/LogoSpinner';
import { FuturisticBackground } from '../components/FuturisticBackground';
import { usePosterSlide } from '../components/series/settings/usePosterSlide';
import { ScenePreview } from '../components/authoring/Menu/ScenePreview';
import { WelcomeUserProfile } from './Welcome/WelcomeUserProfile';
import { WelcomeBrandKitForm } from './Welcome/WelcomeBrandKitForm';
import {
  createNewSeriesApi,
  fetchWorkspaceApi,
  getSpeakersByIdApi,
  requestLinkedinAccessTokenApi,
  triggerAiSoloEpisode,
  updateUserProfileApi,
  updateWorkspaceApi,
  updateWorkspaceLinkedinIntegrationApi,
} from '../helper/api';
import { emptyScheduledEvent } from './Portal/useMeetingCreator';
import {
  DEFAULT_POSTER_PLACEHOLDERS,
  defaultBrandkit,
} from '../helper/constants';
import { useElementSize } from '../hooks/useElementSize';
import { ProgressBar } from '../components/onboarding/ProgressBar';
import { StageArrows } from '../components/onboarding/StageArrows';
import { buttonFunctionalityClasses } from '../components/onboarding/constants';
import { FormSteps } from '../components/onboarding/FormSteps';
import { TextInput } from '../components/Input';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AddBlogPost } from './Portal/AddBlogPost';
import { createSoloInterviewEpisode } from './Portal/createSoloInterviewEpisode';
import { wait } from 'zync-common/helper/wait';
import { episodeTypes } from 'zync-common/types';
import { useLinkedIn } from 'react-linkedin-login-oauth2';
import { onboardingReducerActionNames } from '../reducers/onboarding';
import { notifyUser } from '../components/authoring/hooks';
import { refreshUser } from '../auth';
import { getStaticAssetUrl } from '../helper/getStaticAssetUrl';
import { CrossIcon } from '../components/icons/CrossIcon';
import { CheckInCircleIcon } from '../components/icons/CheckInCircleIcon';
import mixpanel from 'mixpanel-browser';

const createStubEpisode = async ({ speaker, workspaceId, emailAddress }) => {
  const firstSoloSessionTemplate = await createSoloInterviewEpisode({
    title: 'Your First Studio Session',
    questions: [
      {
        text: 'What inspired you to start your company?',
        videoScript:
          '## VIDEO SCRIPT (use this space to jot down talking points)\n' +
          '* Here are some suggestions\n' +
          '    * Talk about your journey and what prompted you to do this.\n' +
          '    * Talk about the values and emotions that got you here so you make a connection with your audience.\n' +
          '* Keep the whole video clip to under 2 minutes.',
        title: 'Founder Journey',
      },
      {
        text: 'Who is your ideal customer and what problem are you solving for them?',
        videoScript:
          '## VIDEO SCRIPT (use this space to jot down talking points)\n' +
          '* Here are some suggestions\n' +
          '    * Identify a clear customer persona.\n' +
          '    * Use relatable scenarios or examples.\n' +
          '    * Highlight common pain points.\n' +
          '    * Emphasize how product solves this emotionally and practically.\n' +
          '    * Share any testimonials or results\n' +
          '* Keep the video to be ~3 minutes',
        title: 'Solving Real Problems',
      },
      {
        text: "What's an unknown fact or common misconception your potential customers have about your product or industry, and what's your perspective on it?",
        videoScript:
          '## VIDEO SCRIPT (use this space to jot down talking points)' +
          '* Here are some suggestions\n' +
          '    * Clearly state the misconception or unknown fact.\n' +
          '    * Provide context to why it exists or is common.\n' +
          '    * Explain the reality and share your perspective\n' +
          '    * Any Support on why you think so - stats / experience / data\n' +
          '    * How this new information and truth can help them\n' +
          '    * What’s the immediate call to action\n' +
          '* Don’t over think and go with the first thing that comes to your mind\n' +
          '* Keep the video to be ~2-3 minutes.',
        title: 'Behind the Misconceptions',
      },
    ],
    eventSpeakers: [speaker],
  });

  return createNewSeriesApi(
    workspaceId,
    emailAddress,
    'Your First Studio Session',
    '' /* empty description */,
    'rsvp',
    firstSoloSessionTemplate,
    emptyScheduledEvent,
    undefined,
    makeRandomId(8),
    false,
    undefined,
    undefined,
    undefined,
    episodeTypes.solo
  );
};

export const createPlaceholderEpisode = async ({
  emailAddress,
  meetingSeriesId,
  workspaceId,
}) => {
  const firstSoloSessionTemplate = await createSoloInterviewEpisode({
    title: 'Placeholder episode',
    questions: [],
    eventSpeakers: [],
    episodeType: episodeTypes.placeholder,
  });

  return createNewSeriesApi(
    workspaceId,
    emailAddress,
    'Placeholder episode',
    '' /* empty description */,
    'rsvp',
    firstSoloSessionTemplate,
    emptyScheduledEvent,
    undefined,
    meetingSeriesId,
    false,
    undefined,
    undefined,
    undefined,
    episodeTypes.placeholder
  );
};

const checkpointNames = {
  STEP_ABOUT: 'STEP_ABOUT',
  INTRO_VIDEO: 'INTRO_VIDEO',
  SETUP_PROFILE: 'SETUP_PROFILE',
  STEP_CONTENT_PILLARS: 'STEP_CONTENT_PILLARS',
  STEP_AUDIENCE: 'STEP_AUDIENCE',
  STEP_CONTENT_STYLE: 'STEP_CONTENT_STYLE',
  SETUP_BRANDKIT: 'SETUP_BRANDKIT',
};

const createPortalStarterKit = async ({
  workspaceId,
  user,
  newBlogPostText,
  onAfterStepComplete,
  newMeetingSeriesId,
  defaultSpeakerId,
}) => {
  try {
    const { emailAddress, userId } = user || {};

    onAfterStepComplete('Getting speaker information...');

    const speaker = await getSpeakersByIdApi(defaultSpeakerId);

    if (!speaker) {
      return;
    }

    onAfterStepComplete('Creating your Space and solo session episode...');

    await Promise.all([
      // this is an example episode that is created along with the new workspace
      createStubEpisode({
        speaker,
        workspaceId,
        emailAddress,
      }),
      // this is a placeholder / loader episode that will be deleted after real one is created
      createPlaceholderEpisode({
        emailAddress,
        meetingSeriesId: newMeetingSeriesId + '_placeholder',
        workspaceId,
      }),
    ]);

    triggerAiSoloEpisode({
      meetingSeriesId: newMeetingSeriesId,
      workspaceId,
      userId,
      blogPostText: newBlogPostText,
    });

    onAfterStepComplete('Done!');

    await wait(1000);

    onAfterStepComplete('');
  } catch (error) {
    logerror({ ...fromError(error) });
  }
};

const markOnboardingCompleted = (userId) => {
  try {
    localStorage.setItem('onboardingCompleted-' + userId, 'true');
  } catch (error) {}
};

export const checkIsOnboardingCompleted = (userId) => {
  try {
    const onboardingCompleted = localStorage.getItem(
      'onboardingCompleted-' + userId
    );
    return onboardingCompleted === 'true';
  } catch (error) {
    return true;
  }
};

export const isNewUserOnboarded = (user) => {
  const isOnboarded = checkIsOnboardingCompleted(user.userId);
  const isProfileSetupPending = user.bio === null || user.avatarUrl === null;

  return !isOnboarded && isProfileSetupPending;
};

export const isNewUserOnboardedSolo = (user) => {
  const isOnboarded = checkIsOnboardingCompleted(user.userId);

  return !isOnboarded;
};

export const WelcomeDirect = ({ workspaceId }) => {
  const user = useSelector((_st) => _st.auth.user, shallowEqual);
  const linkedinOnboarding = useSelector(
    (_st) => _st.onboarding?.linkedin,
    shallowEqual
  );
  const { userId, userName } = user;
  const firstName = userName.split(' ')[0];
  const [creationStatus, setCreationStatus] = useState('');

  const checkpoints = {
    [checkpointNames.STEP_ABOUT]: {
      heading: 'About',
      topics: ['About'],
    },
    [checkpointNames.STEP_CONTENT_PILLARS]: {
      heading: 'Content Pillars',
      topics: [
        'Content Pillars',
        'What are some areas that you’d like to build content for?',
      ],
    },
    [checkpointNames.STEP_AUDIENCE]: {
      heading: 'Audience',
      topics: ['Audience'],
    },
    [checkpointNames.STEP_CONTENT_STYLE]: {
      heading: 'Content Style',
      topics: ['Content Style'],
    },
    [checkpointNames.SETUP_BRANDKIT]: {
      heading: 'Setup Brand Kit',
      topics: [
        'Setup your Brand Kit',
        'You can adjust these settings in your Workspace Settings Later',
      ],
    },
  };

  const [checkpointsData] = useState(checkpoints);
  const [
    STEP_ABOUT,
    STEP_CONTENT_PILLARS,
    STEP_AUDIENCE,
    STEP_CONTENT_STYLE,
    SETUP_BRANDKIT,
  ] = [
    checkpointsData[checkpointNames.STEP_ABOUT],
    checkpointsData[checkpointNames.STEP_CONTENT_PILLARS],
    checkpointsData[checkpointNames.STEP_AUDIENCE],
    checkpointsData[checkpointNames.STEP_CONTENT_STYLE],
    checkpointsData[checkpointNames.SETUP_BRANDKIT],
  ];

  const history = useHistory();
  const { createWorkspace } = useUserWorkspaces();
  const [checkpoint, setCheckpoint] = useState(STEP_ABOUT);
  const [workspaceName, setWorkspaceName] = useState('');
  const workspaceNameSetRef = useRef(false);
  const [aboutSpeaker, setAboutSpeaker] = useState('');
  const [contentPillar, setContentPillar] = useState('');
  const [contentPillars, setContentPillars] = useState(['Entrepreneurship']);
  const [audienceInformation, setAudienceInformation] = useState('');
  const [contentStyleText, setContentStyleText] = useState('');
  const [redirectToDesigner] = useState(false);
  const [autoCreatedMeetingSeriesId] = useState('');
  const [nextButtonStyling, setNextButtonStyling] = useState(
    buttonFunctionalityClasses.ENABLE
  );
  const [previousButtonStyling, setPreviousButtonStyling] = useState(
    buttonFunctionalityClasses.DISABLE
  );
  const [showInputError, setShowInputError] = useState(false);
  const [inputErrorMessage, setInputErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [brandKit, setBrandKit] = useState(defaultBrandkit);
  const [soloSettings, setSoloSettings] = useState({});
  const { width: posterSlideContainerWidth, ref: posterSlideContainerRef } =
    useElementSize();

  useEffect(() => {
    const getWorkspace = async () => {
      if (workspaceId) {
        setIsLoading(true);
        const { result } = await fetchWorkspaceApi(workspaceId);

        const { soloSettings, brandKit } = result || {};

        setAboutSpeaker(soloSettings.aboutSpeaker || '');
        setBrandKit({ brandKit });
        setContentPillars(soloSettings.contentPillars);
        setAudienceInformation(soloSettings.audienceInformation);
        setContentStyleText(soloSettings.contentStyle || '');
        setSoloSettings(soloSettings);
        setIsLoading(false);
      }
    };

    getWorkspace();
  }, [workspaceId]);

  useEffect(() => {
    if (!workspaceName && !workspaceNameSetRef.current && firstName) {
      workspaceNameSetRef.current = true;
      setWorkspaceName(`${firstName}'s Space`);
    }
  }, [workspaceName, firstName]);

  const maxInputCharacters = 32;

  const isShortWorkspaceName = !workspaceName.length;
  const isLongWorkspaceName = workspaceName.length > maxInputCharacters;
  const isInvalidWorkspaceName = isShortWorkspaceName || isLongWorkspaceName;

  const styleToEnablePreviousButtonAndNextButton = () => {
    setPreviousButtonStyling(buttonFunctionalityClasses.ENABLE);
    setNextButtonStyling(buttonFunctionalityClasses.ENABLE);
  };

  /** Previous and Next button styling. */
  useEffect(() => {
    switch (checkpoint) {
      default:
        styleToEnablePreviousButtonAndNextButton();
        break;
    }
  }, [workspaceName, checkpoint, isInvalidWorkspaceName]);

  /** Workspace Input error handling. */
  useEffect(() => {
    if (checkpoint === STEP_ABOUT) {
      if (isShortWorkspaceName) {
        return;
      } else if (isLongWorkspaceName) {
        return;
      }
    }
    setShowInputError(false);
    setInputErrorMessage('');
  }, [
    workspaceName,
    checkpoint,
    STEP_ABOUT,
    isShortWorkspaceName,
    isLongWorkspaceName,
  ]);

  const setPreviousCheckpoint = (currentCheckpoint = checkpoint) => {
    switch (currentCheckpoint) {
      case STEP_CONTENT_PILLARS: {
        setCheckpoint(STEP_ABOUT);
        break;
      }
      case STEP_AUDIENCE: {
        setCheckpoint(STEP_CONTENT_PILLARS);
        break;
      }
      case STEP_CONTENT_STYLE: {
        setCheckpoint(STEP_AUDIENCE);
        break;
      }
      case SETUP_BRANDKIT: {
        setCheckpoint(STEP_CONTENT_STYLE);
        break;
      }
      default:
    }
  };

  const setNextCheckpoint = (currentCheckpoint = checkpoint) => {
    switch (currentCheckpoint) {
      case STEP_ABOUT:
        setCheckpoint(STEP_CONTENT_PILLARS);
        break;
      case STEP_CONTENT_PILLARS:
        setCheckpoint(STEP_AUDIENCE);
        break;
      case STEP_AUDIENCE:
        setCheckpoint(STEP_CONTENT_STYLE);
        break;
      case STEP_CONTENT_STYLE:
        setCheckpoint(SETUP_BRANDKIT);
        break;
      default:
    }
  };

  const handlePreviousClick = () => {
    // If the DISABLE class is applied, do not allow previous click.
    if (previousButtonStyling === buttonFunctionalityClasses.DISABLE) {
      return;
    }
    setPreviousCheckpoint();
  };

  const handleNextClick = () => {
    // If the DISABLE class is applied, do not allow next click.
    if (nextButtonStyling === buttonFunctionalityClasses.DISABLE) {
      return;
    }
    if (checkpoint === SETUP_BRANDKIT) {
      setIsLoading(true);
      mixpanel.track('Finished with onboarding steps');
      createWorkspaceAndUpdateUser(workspaceId);
    } else {
      setNextCheckpoint();
    }
  };

  const newMeetingSeriesId = useRef(makeRandomId(8));

  const createWorkspaceAndUpdateUser = async (workspaceIdProp) => {
    try {
      if (userId) {
        const workspaceData = {
          brandKit: brandKit.brandKit,
          soloSettings: {
            ...soloSettings,
            aboutSpeaker,
            audienceInformation,
            contentPillars,
            contentStyle: contentStyleText,
            newSessionEmail: 'true',
            emailReminder24h: 'true',
          },
          requiresOnboarding: false,
        };

        if (
          linkedinOnboarding?.authenticatedUserId &&
          linkedinOnboarding?.auth
        ) {
          workspaceData.integrations = {};
          workspaceData.integrations.linkedin = {
            authenticatedUserId: linkedinOnboarding.authenticatedUserId,
            auth: linkedinOnboarding.auth,
          };
        }

        setCreationStatus('Creating a new show...');

        mixpanel.track('Creating workspace after finishing onboarding');

        let workspace;

        if (workspaceIdProp) {
          const { result } = await updateWorkspaceApi(
            workspaceIdProp,
            workspaceData,
            userId
          );
          workspace = result;
        } else {
          const { workspace: result } = await createWorkspace(
            workspaceName,
            userId,
            workspaceData
          );
          workspace = result;
        }

        mixpanel.track('Created workspace after finishing onboarding');

        if (!workspaceIdProp) {
          const workspaceId = workspace.workspaceId;
          const defaultSpeakerId = workspace.soloSettings?.defaultSpeaker;

          markOnboardingCompleted(userId);

          await createPortalStarterKit({
            newMeetingSeriesId: newMeetingSeriesId.current,
            newBlogPostText: contentStyleText,
            onAfterStepComplete: (text) => setCreationStatus(text),
            workspaceId,
            user,
            defaultSpeakerId,
          });

          mixpanel.track(
            'Created portal starter kit after finishing onboarding',
            { distinct_id: userId }
          );

          const workspaceUrl = `/portal?workspaceId=${workspaceId}`;

          mixpanel.track('Onboarding: Completed Onboarding', {
            distinct_id: userId,
          });

          history.push(workspaceUrl);
        } else {
          mixpanel.track('Onboarding: Completed Onboarding', {
            distinct_id: userId,
          });
          history.go(0);
        }
      }
    } catch (err) {
      setIsLoading(false);
      logerror({
        message: `Error attempting to call either createWorkspace or updateUserApi from the Welcome page. ${err.message}`,
        stacktrace: err.stack | err,
      });
    }
    setIsLoading(false);
  };

  /** Returns true/false depending on if the checkpoint in state comes before the argument, checkpoint. */
  const isBeforeCheckpoint = (currentCheckpointName) => {
    const currentCheckpoint = checkpointsData[currentCheckpointName];
    const stateCheckpoint = checkpoint;
    if (currentCheckpoint === stateCheckpoint) {
      return false;
    }
    for (let checkpoint in checkpointsData) {
      // found the state checkpoint first
      if (stateCheckpoint === checkpointsData[checkpoint]) {
        return true;
      }
      // found the current checkpoint first
      if (currentCheckpoint === checkpointsData[checkpoint]) {
        return false;
      }
    }
  };

  /** Returns true/false depending on if the checkpoint in state is at the argument, checkpoint. */
  const isAtCheckpoint = (currentCheckpointName) => {
    const currentCheckpoint = checkpointsData[currentCheckpointName];
    return currentCheckpoint === checkpoint;
  };

  const ProgressStates = {
    BEFORE: 'BEFORE',
    CURRENT: 'CURRENT',
    AFTER: 'AFTER',
  };

  /** Every checkpoint is looped over to determine its relationship to the checkpoint in state.
   *  This is used to determine the color & attributes that the currentCheckpoint should contain.
   *  If the checkpoint in state comes before currentCheckpoint, then its state is BEFORE
   *  If the checkpoint in state is at currentCheckpoint, then its state is CURRENT
   *  If the checkpoint in state is after currentCheckpoint, then its state is AFTER
   */
  const getProgressState = (currentCheckpoint) => {
    const isBefore = isBeforeCheckpoint(currentCheckpoint);
    const isAt = isAtCheckpoint(currentCheckpoint);
    if (isBefore) {
      return ProgressStates.BEFORE;
    }
    if (isAt) {
      return ProgressStates.CURRENT;
    }
    return ProgressStates.AFTER;
  };

  const { posterSlide } = usePosterSlide(
    DEFAULT_POSTER_PLACEHOLDERS,
    undefined,
    undefined,
    'scene_solo_studio_brand_kit'
  );

  if (isLoading) {
    return (
      <div className="w-full h-[100vh] grid place-content-center">
        <LogoSpinnerFullCentered />
        {creationStatus && (
          <p className="text-center font-medium text-blue-gray text-sm">
            {creationStatus}
          </p>
        )}
      </div>
    );
  }
  if (redirectToDesigner && autoCreatedMeetingSeriesId) {
    return <Redirect to={`/e/${autoCreatedMeetingSeriesId}/designer`} />;
  }
  return (
    <div className="w-screen md:h-screen flex justify-center items-center h-fit md:py-0 py-8">
      <FuturisticBackground />
      <div className="z-50 flex flex-col relative w-[calc(100%-34px)] mx-auto md:min-w-[800px] md:max-w-[1000px] bg-white px-1.5 md:px-0 shadow-800 rounded-xl">
        <div className="bg-white flex flex-col justify-center items-center min-h-[24px] md:min-h-[52px] mt-[34px] mb-5 md:mt-8 md:mb-8 space-y-3 md:space-y-2 md:mx-0 mx-8">
          <span className="text-base md:text-xl font-semibold md:font-bold text-blue-dark text-center md:text-left">
            {checkpoint?.topics[0]}
          </span>
          {checkpoint?.topics[1] && (
            <span className="text-xs md:text-md font-medium md:font-bold text-blue-gray md:text-light-gray md:mt-4 text-center md:text-left">
              {checkpoint?.topics[1]}
            </span>
          )}
        </div>

        {/* Data */}
        <div className="bg-white flex justify-between items-center md:px-3 space-x-2">
          <div className="flex bg-purple bg-opacity-[5%] grow min-h-[82px] md:min-h-[250px] md:mx-4 rounded-md justify-between items-center">
            {checkpoint === STEP_ABOUT && (
              <div className="flex flex-col min-w-full items-center md:space-y-2 px-5 md:px-0 py-5 md:pt-4 md:pb-0">
                <label>
                  <span className="block text-blue-dark font-medium text-sm mb-4 text-center">
                    Share your expertise, achievements and uniqueness
                  </span>
                  <textarea
                    className={classNames(
                      'w-[500px] p-3 border-solid border box-border border-gray rounded-md md:text-base text-sm resize-none',
                      showInputError
                        ? 'focus:border-red focus:outline-none outline-red'
                        : 'focus:outline-none focus:border-purple outline-purple'
                    )}
                    maxLength={1000}
                    placeholder={
                      'I talk about marketing and Sales for B2B startups…'
                    }
                    onBlur={(event) => {
                      if (event.target.value) {
                        mixpanel.track('Onboarding: Updated About Section', {
                          distinct_id: userId,
                        });
                      }
                    }}
                    onChange={(event) => setAboutSpeaker(event.target.value)}
                    value={aboutSpeaker}
                    rows={4}
                  />
                </label>
                {showInputError && (
                  <span className="text-xs text-[#D14343] font-bold h-2 text-center md:text-left">
                    {inputErrorMessage}
                  </span>
                )}
              </div>
            )}

            {checkpoint === STEP_CONTENT_PILLARS && (
              <div className="flex flex-col gap-4 p-6 w-full">
                <h3 className="text-blue-dark font-medium text-sm text-center">
                  What are some areas that you’d like to build content for?
                </h3>
                <div className="w-[52.5%] mx-auto">
                  <TextInput
                    label={
                      <p className="text-[#97A0AF] font-medium text-xs text-center">
                        Add at least 3 content pillars that you’d want to
                        generate content around
                      </p>
                    }
                    value={contentPillar}
                    placeholder="Sales"
                    onChange={(event, value) => {
                      setContentPillar(value);
                    }}
                    onEnter={() => {
                      const trimmedWord = contentPillar.trim();
                      if (trimmedWord.length === 0) return;

                      mixpanel.track('Onboarding: Updated Content Pillars', {
                        distinct_id: userId,
                        value: trimmedWord,
                      });

                      if (
                        contentPillars.some(
                          (word) =>
                            word.toLowerCase() === trimmedWord.toLowerCase()
                        )
                      ) {
                        return;
                      }

                      setContentPillar('');

                      setContentPillars((contentPillars) => [
                        ...contentPillars,
                        trimmedWord,
                      ]);
                    }}
                    maxLength={300}
                  />
                </div>
                {contentPillars.length > 0 && (
                  <div>
                    <div className="flex gap-2 flex-wrap w-[55%] max-w-[55%] mx-auto overflow-hidden">
                      {contentPillars.map((word) => (
                        <div
                          className="bg-purple text-white p-1 text-sm rounded flex gap-2 items-center w-fit max-w-[200px]"
                          key={word}
                        >
                          <span className="line-clamp-1 text-ellipsis">
                            {word}
                          </span>
                          <button
                            type="button"
                            className="flex items-center"
                            onClick={() => {
                              setContentPillars((contentPillars) =>
                                contentPillars.filter((w) => w !== word)
                              );
                            }}
                          >
                            <FontAwesomeIcon icon="times" />
                          </button>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            )}

            {checkpoint === STEP_AUDIENCE && (
              <div className="flex flex-col min-w-full items-center md:space-y-2 px-5 md:px-0 py-5 md:pt-4 md:pb-0">
                <label>
                  <span className="block text-blue-dark font-medium text-sm mb-4 text-center">
                    Who would be interested in the content you produce?
                  </span>
                  <textarea
                    className={classNames(
                      'w-[500px] p-3 border-solid border box-border border-gray rounded-md md:text-base text-sm resize-none',
                      showInputError
                        ? 'focus:border-red focus:outline-none outline-red'
                        : 'focus:outline-none focus:border-purple outline-purple'
                    )}
                    maxLength={1000}
                    onBlur={(event) => {
                      if (event.target.value) {
                        mixpanel.track('Onboarding: Updated About Audience', {
                          distinct_id: userId,
                        });
                      }
                    }}
                    placeholder="My content would be relevant to …"
                    onChange={(event) =>
                      setAudienceInformation(event.target.value)
                    }
                    value={audienceInformation}
                    rows={4}
                  />
                </label>
                {showInputError && (
                  <span className="text-xs text-[#D14343] font-bold h-2 text-center md:text-left">
                    {inputErrorMessage}
                  </span>
                )}
              </div>
            )}

            {checkpoint === STEP_CONTENT_STYLE && (
              <div className="flex flex-col min-w-full items-center md:space-y-2 px-5 py-5">
                <AddBlogPost
                  newBlogPost={contentStyleText}
                  setNewBlogPost={setContentStyleText}
                  onboarding
                />
              </div>
            )}

            {checkpoint === SETUP_BRANDKIT && (
              <div className="flex gap-6 md:justify-between w-full mx-5 items-center md:flex-row flex-col-reverse max-w-full min-w-0">
                <WelcomeBrandKitForm
                  workspace={workspaceId ? brandKit : defaultBrandkit}
                  onBrandKitChange={setBrandKit}
                />
                {posterSlide && (
                  <div
                    ref={posterSlideContainerRef}
                    className="lg:w-[600px] md:w-[400px] w-full md:mt-0 mt-5"
                  >
                    <div className="w-fit mx-auto md:ml-auto">
                      <ScenePreview
                        scene={posterSlide}
                        width={
                          isMobile
                            ? 300
                            : posterSlideContainerWidth
                            ? posterSlideContainerWidth
                            : 300
                        }
                        height={
                          isMobile
                            ? (300 / 16) * 9
                            : posterSlideContainerWidth
                            ? (posterSlideContainerWidth / 16) * 9
                            : (300 / 16) * 9
                        }
                        brandKit={brandKit.brandKit}
                      />
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
        <FormSteps
          getProgressState={getProgressState}
          checkpointsData={checkpointsData}
          ProgressStates={ProgressStates}
        />
        <StageArrows
          previousButtonStyling={previousButtonStyling}
          nextButtonStyling={nextButtonStyling}
          handleNextClick={handleNextClick}
          handlePreviousClick={handlePreviousClick}
          showPreviousButton={checkpoint !== STEP_ABOUT}
        />
        <ProgressBar
          checkpointsData={checkpointsData}
          getProgressState={getProgressState}
        />
      </div>
    </div>
  );
};

const linkedinCreds = process.env.REACT_APP_LINKEDIN_OAUTH_CREDENTIAL
  ? JSON.parse(process.env.REACT_APP_LINKEDIN_OAUTH_CREDENTIAL)
  : null;

const getFirstObjectEntryValue = (object) => Object.values(object)[0];

const IntegrateWithLinkedin = ({ workspace }) => {
  const dispatch = useDispatch();

  const user = useSelector((_st) => _st.auth.user, shallowEqual);
  const linkedin = useSelector((_st) => _st.onboarding.linkedin, shallowEqual);

  const { userId } = user;
  const { authenticatedUserId } = linkedin || {};

  const { client_id, redirect_uri } = linkedinCreds;

  const history = useHistory();

  useEffect(() => {
    mixpanel.track('Onboarding: Shown LinkedIn modal', {
      distinct_id: userId,
    });
  }, [userId]);

  const { linkedInLogin } = useLinkedIn({
    clientId: client_id,
    scope: 'r_basicprofile w_member_social',
    redirectUri: redirect_uri, // for Next.js, you can use `${typeof window === 'object' && window.location.origin}/linkedin`
    onSuccess: async (code) => {
      try {
        const {
          data: { auth, profile },
        } = await requestLinkedinAccessTokenApi(code);

        if (profile) {
          const linkedinProfileData = {
            avatarUrl:
              profile.profilePicture['displayImage~'].elements.at(-1)
                ?.identifiers?.[0]?.identifier,
            userName: `${getFirstObjectEntryValue(
              profile.firstName.localized
            )} ${getFirstObjectEntryValue(profile.lastName.localized)}`,
            bio: getFirstObjectEntryValue(profile.headline.localized),
          };

          await updateUserProfileApi(userId, linkedinProfileData);

          await refreshUser(dispatch, userId);
        }

        mixpanel.track('Onboarding: Connected with LinkedIn successfully', {
          distinct_id: userId,
        });

        dispatch({
          type: onboardingReducerActionNames.SAVE_LINKEDIN_DATA,
          payload: {
            auth,
            profile,
            authenticatedUserId: userId,
          },
        });

        if (workspace && !workspace?.integrations?.linkedin) {
          await updateWorkspaceLinkedinIntegrationApi(workspace.workspaceId, {
            auth,
            profile,
            authenticatedUserId: userId,
          });

          markOnboardingCompleted(userId);
        }
      } catch (error) {
        notifyUser('We could not connect to Linkedin');
      } finally {
      }
    },
    onError: (error) => {
      setTimeout(() => {}, 5000);
    },
  });

  const handleContinue = () => {
    dispatch({
      type: onboardingReducerActionNames.PROCEED_LINKEDIN_STEP,
    });

    if (workspace && workspace.workspacePlan === 'solo') {
      history.push(`/solo`);
      markOnboardingCompleted(userId);
    }
  };

  return (
    <div className="w-screen md:h-screen">
      <FuturisticBackground />
      <div className="z-50 flex flex-col absolute w-[calc(100%-6rem)] h-[calc(100%-6rem)] md:top-1/2 left-1/2 -translate-x-1/2 md:-translate-y-1/2 bg-white shadow-800 rounded-xl top-8 overflow-hidden max-w-[1440px]">
        {/* Data */}
        <button
          className="absolute top-4 right-4"
          type="button"
          onClick={() => {
            mixpanel.track('Onboarding: Canceled LinkedIn modal', {
              distinct_id: userId,
            });
            handleContinue();
          }}
        >
          <CrossIcon className="fill-blue-gray" height={14} width={14} />
        </button>
        <div className="h-full flex gap-10 rounded-xl">
          <div className="h-full shrink-0 rounded-tl-xl rounded-bl-xl overflow-hidden">
            <img
              src={getStaticAssetUrl('linkedin_integration_img.jpg')}
              alt="Integrate with Linkedin"
              className="h-full aspect-[690/892]"
            />
          </div>
          <div className="bg-white flex flex-col justify-center items-center]">
            <div className="max-w-[612px] pr-8">
              <h1 className="text-[#2A63A5] text-[24px] font-semibold mb-3">
                Connect to LinkedIn
              </h1>
              <p className="text-blue-gray mb-10">
                By connecting your LinkedIn account, you'll unlock detailed
                analytics for your posts. Gain insights into who is engaging
                with your content, the reach of your posts, and the impact on
                your professional network.
              </p>
              <ul className="flex flex-col gap-8 font-semibold text-[#2A63A5] mb-10">
                <li className="flex gap-3 items-center">
                  <CheckInCircleIcon color="#2A63A5" width={24} height={24} />{' '}
                  Personalize your experience
                </li>
                <li className="flex gap-3 items-center">
                  <CheckInCircleIcon color="#2A63A5" width={24} height={24} />{' '}
                  One-click post to Linkedin
                </li>
                <li className="flex gap-3 items-center">
                  <CheckInCircleIcon color="#2A63A5" width={24} height={24} />{' '}
                  Analytics on content
                </li>
              </ul>
            </div>
            <div className="flex gap-2 w-full md:my-0 my-5 flex-col max-w-full min-w-0">
              <>
                {authenticatedUserId ? (
                  <div className="flex items-center text-[#2A63A5] gap-2 border border-[#2A63A5] bg-[#2A63A5] bg-opacity-5 rounded-md p-2 w-fit">
                    Successfully connected to{' '}
                    <img
                      className="h-4"
                      alt="linkedin integration successful"
                      src="https://content.linkedin.com/content/dam/me/business/en-us/amp/brand-site/v2/bg/LI-Logo.svg.original.svg"
                    />
                  </div>
                ) : (
                  <img
                    onClick={() => {
                      linkedInLogin();
                    }}
                    src={getStaticAssetUrl('linkedin-button.png')}
                    alt="Sign in with Linked In"
                    height={48}
                    width={322}
                    style={{ cursor: 'pointer' }}
                  />
                )}
                {authenticatedUserId ? (
                  <button
                    onClick={handleContinue}
                    type="button"
                    className="w-fit block mt-4"
                  >
                    <span className="text-sm text-white font-medium bg-[#2A63A5] px-4 py-2 rounded-md">
                      Continue
                    </span>
                  </button>
                ) : null}
              </>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const WelcomeInvite = () => {
  const checkpoints = {
    [checkpointNames.SETUP_PROFILE]: {
      heading: 'Setup User Profile',
      topics: [
        'Setup your profile',
        'You can adjust these values in your Profile settings later',
      ],
    },
  };

  const userId = useSelector((_st) => _st.auth.user?.userId);
  const { width: posterSlideContainerWidth, ref: posterSlideContainerRef } =
    useElementSize();
  const [checkpointsData] = useState(checkpoints);
  const [SETUP_PROFILE] = [checkpointsData[checkpointNames.SETUP_PROFILE]];

  const history = useHistory();
  const [checkpoint] = useState(SETUP_PROFILE);
  const [workspaceName] = useState('');
  const [redirectToDesigner] = useState(false);
  const [autoCreatedMeetingSeriesId] = useState('');
  const [nextButtonStyling, setNextButtonStyling] = useState(
    buttonFunctionalityClasses.ENABLE
  );
  const [previousButtonStyling] = useState(buttonFunctionalityClasses.DISABLE);

  const [isLoading, setIsLoading] = useState(false);
  const [brandKit, setBrandKit] = useState(defaultBrandkit);

  const { userWorkspaces } = useUserWorkspaces();

  const firstWorkspace = userWorkspaces[0];
  useEffect(() => {
    if (firstWorkspace) {
      setBrandKit(firstWorkspace);
    }
  }, [firstWorkspace]);

  const maxInputCharacters = 32;

  const isShortWorkspaceName = !workspaceName.length;
  const isLongWorkspaceName = workspaceName.length > maxInputCharacters;
  const isInvalidWorkspaceName = isShortWorkspaceName || isLongWorkspaceName;

  const styleToEnableNextButton = () => {
    setNextButtonStyling(buttonFunctionalityClasses.ENABLE);
  };

  /** Previous and Next button styling. */
  useEffect(() => {
    switch (checkpoint) {
      default:
        styleToEnableNextButton();
        break;
    }
  }, [workspaceName, checkpoint, isInvalidWorkspaceName]);

  const setPreviousCheckpoint = (currentCheckpoint = checkpoint) => {
    switch (currentCheckpoint) {
      default:
    }
  };

  const setNextCheckpoint = (currentCheckpoint = checkpoint) => {
    switch (currentCheckpoint) {
      default: {
      }
    }
  };

  const handlePreviousClick = () => {
    // If the DISABLE class is applied, do not allow previous click.
    if (previousButtonStyling === buttonFunctionalityClasses.DISABLE) {
      return;
    }
    setPreviousCheckpoint();
  };

  const handleNextClick = () => {
    // If the DISABLE class is applied, do not allow next click.
    if (nextButtonStyling === buttonFunctionalityClasses.DISABLE) {
      return;
    }
    if (checkpoint === SETUP_PROFILE) {
      setIsLoading(true);
      markOnboardingCompleted(userId);
      mixpanel.track('Finished with onboarding steps');
      history.push('/portal');
    } else {
      setNextCheckpoint();
    }
  };

  /** Returns true/false depending on if the checkpoint in state comes before the argument, checkpoint. */
  const isBeforeCheckpoint = (currentCheckpointName) => {
    const currentCheckpoint = checkpointsData[currentCheckpointName];
    const stateCheckpoint = checkpoint;
    if (currentCheckpoint === stateCheckpoint) {
      return false;
    }
    for (let checkpoint in checkpointsData) {
      // found the state checkpoint first
      if (stateCheckpoint === checkpointsData[checkpoint]) {
        return true;
      }
      // found the current checkpoint first
      if (currentCheckpoint === checkpointsData[checkpoint]) {
        return false;
      }
    }
  };

  /** Returns true/false depending on if the checkpoint in state is at the argument, checkpoint. */
  const isAtCheckpoint = (currentCheckpointName) => {
    const currentCheckpoint = checkpointsData[currentCheckpointName];
    return currentCheckpoint === checkpoint;
  };

  const ProgressStates = {
    BEFORE: 'BEFORE',
    CURRENT: 'CURRENT',
    AFTER: 'AFTER',
  };

  /** Every checkpoint is looped over to determine its relationship to the checkpoint in state.
   *  This is used to determine the color & attributes that the currentCheckpoint should contain.
   *  If the checkpoint in state comes before currentCheckpoint, then its state is BEFORE
   *  If the checkpoint in state is at currentCheckpoint, then its state is CURRENT
   *  If the checkpoint in state is after currentCheckpoint, then its state is AFTER
   */
  const getProgressState = (currentCheckpoint) => {
    const isBefore = isBeforeCheckpoint(currentCheckpoint);
    const isAt = isAtCheckpoint(currentCheckpoint);
    if (isBefore) {
      return ProgressStates.BEFORE;
    }
    if (isAt) {
      return ProgressStates.CURRENT;
    }
    return ProgressStates.AFTER;
  };

  const { posterSlide } = usePosterSlide(
    DEFAULT_POSTER_PLACEHOLDERS,
    null,
    true,
    'user_profile_preview'
  );

  if (isLoading) {
    return (
      <div className="w-full h-full grid place-content-center">
        <LogoSpinnerFullCentered />
      </div>
    );
  }
  if (redirectToDesigner && autoCreatedMeetingSeriesId) {
    return <Redirect to={`/e/${autoCreatedMeetingSeriesId}/designer`} />;
  }
  return (
    <div className="w-screen md:h-screen">
      <FuturisticBackground />
      <div className="z-50 flex flex-col absolute w-[calc(100%-34px)] mx-auto md:min-w-[800px] md:max-w-[1000px] md:top-1/2 left-1/2 -translate-x-1/2 md:-translate-y-1/2 bg-white px-1.5 md:px-0 shadow-800 rounded-xl top-8">
        <div className="bg-white flex flex-col justify-center items-center min-h-[52px] my-6 md:my-8 space-y-3 md:space-y-2">
          <span className="text-base md:text-xl font-semibold md:font-bold text-blue-dark text-center md:text-left">
            {checkpoint?.topics[0]}
          </span>
          {checkpoint?.topics[1] && (
            <span className="text-xs md:text-md font-medium md:font-bold text-blue-gray md:text-light-gray text-center md:text-left">
              {checkpoint?.topics[1]}
            </span>
          )}
        </div>

        {/* Data */}
        <div className="bg-white flex justify-between items-center md:px-3 space-x-2">
          <div className="flex bg-purple bg-opacity-[5%] grow min-h-[153px] md:min-h-[250px] mx-2 md:mx-4 md:mb-5 rounded-md justify-between items-center">
            {checkpoint === SETUP_PROFILE && (
              <div
                ref={isMobile ? posterSlideContainerRef : undefined}
                className="flex gap-6 w-full mx-5 md:my-0 my-5 md:flex-row flex-col-reverse md:items-start items-center max-w-full min-w-0"
              >
                <WelcomeUserProfile />
                {posterSlide && (
                  <div
                    ref={isMobile ? undefined : posterSlideContainerRef}
                    className="md:w-[400px] w-full"
                  >
                    <ScenePreview
                      scene={posterSlide}
                      width={
                        posterSlideContainerWidth
                          ? posterSlideContainerWidth
                          : 200
                      }
                      height={
                        posterSlideContainerWidth
                          ? (posterSlideContainerWidth / 16) * 9
                          : (200 / 16) * 9
                      }
                      brandKit={brandKit.brandKit}
                    />
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
        <FormSteps
          getProgressState={getProgressState}
          checkpointsData={checkpointsData}
          ProgressStates={ProgressStates}
        />
        <StageArrows
          previousButtonStyling={previousButtonStyling}
          nextButtonStyling={nextButtonStyling}
          handleNextClick={handleNextClick}
          handlePreviousClick={handlePreviousClick}
        />
        <ProgressBar
          checkpointsData={checkpointsData}
          getProgressState={getProgressState}
        />
      </div>
    </div>
  );
};

const SoloWelcome = () => {
  const user = useSelector((state) => state.auth?.user, shallowEqual);
  const linkedinDone = useSelector((state) => state.onboarding?.linkedinDone);
  const params = new URLSearchParams(window.location.search);
  const [isNewUser, setIsNewUser] = useState(false);
  const isInitialized = useRef(false);

  const { userWorkspaces } = useUserWorkspaces();

  const onlySoloWorkspace =
    userWorkspaces.length === 1 && userWorkspaces[0].workspacePlan === 'solo'
      ? userWorkspaces[0]
      : null;

  useEffect(() => {
    if (user && !isInitialized.current) {
      isInitialized.current = true;
      setIsNewUser(isNewUserOnboardedSolo(user));
    }
  }, [user]);

  useEffect(() => {
    mixpanel.track('Solo Welcome Onboarding Viewed');
  }, []);

  switch (true) {
    case !onlySoloWorkspace?.integrations?.linkedin && !linkedinDone: {
      return <IntegrateWithLinkedin workspace={onlySoloWorkspace} />;
    }

    case params.get('invite') === 'true' || isNewUser: {
      return <WelcomeInvite />;
    }

    default: {
      return <WelcomeDirect />;
    }
  }
};
export default SoloWelcome;
