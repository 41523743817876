import { useCallback, useEffect, useMemo } from 'react';
import { linkedInIcon } from './PublishMenus';
import { LinkedinPost } from './LinkedinPost';
import { useParams } from 'react-router-dom';
import { scrollToElement } from './YoutubePosts';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf } from '@fortawesome/free-solid-svg-icons/faFilePdf';
import axios from 'axios';
import { PROCESSOR_BASE_URL } from '../../../helper/constants';
import { useSelector } from 'react-redux';
import { updateCustomerEngagementStagesApi } from '../../../helper/api';
import zyncCustomerEngagementStages from 'zync-common/hubspot/zyncCustomerEngagementStages';
import mixpanel from 'mixpanel-browser';
import { useTrackActivity } from '../../../hooks/track';

export const LinkedInPosts = ({
  highlights,
  saveHighlightSocialMediaPost,
  saveShortsSocialMediaPost,
  shorts = [],
  posterUrl,
  workspace,
  series,
  breadcrumbs,
  carouselPdfUrl,
  shared,
  refreshContentKit,
  isSoloEpisode,
}) => {
  const userId = useSelector((st) => st.auth?.user?.userId);
  const { meetingSeriesId, section } = useParams();

  const { brandKit } = workspace || {};
  const linkedinAccountOwnerUserId =
    workspace?.integrations?.linkedin?.authenticatedUserId;

  const track = useTrackActivity();

  useEffect(() => {
    track('Content Kit: LinkedIn Section Loaded');
  }, [track]);

  const linkedInHighlights = useMemo(
    () =>
      highlights
        .filter((h) =>
          isSoloEpisode ? false : h.autoGenerateSocialMediaVideo === 'linkedIn'
        )
        .map((h) => ({
          highlightId: h.highlightId,
          title: h.title,
          thumbnailUrl: h.HIGHLIGHT_THUMBNAIL.imageUrl,
          playbackUrl:
            h?.HIGHLIGHT_EDITED_LANDSCAPE?.playbackUrl ||
            h?.HIGHLIGHT_RAW_LANDSCAPE?.playbackUrl,
          postTitle:
            h.HIGHLIGHT_SOCIAL_MEDIA_POSTS.socialMediaPosts.linkedIn.text,
          files:
            h?.HIGHLIGHT_EDITED_LANDSCAPE?.files ||
            h?.HIGHLIGHT_RAW_LANDSCAPE?.files ||
            [],
          uploadedToLinkedIn: h?.uploadedToLinkedin,
        })),
    [highlights, isSoloEpisode]
  );

  const linkedInSoloHighlights = useMemo(
    () =>
      highlights
        .filter((h) => h?.HIGHLIGHT_EDITED_SQUARE)
        .map((h) => ({
          highlightId: h.highlightId,
          title: h.title,
          thumbnailUrl: h?.HIGHLIGHT_SQUARE_THUMBNAIL?.imageUrl,
          playbackUrl: h.HIGHLIGHT_EDITED_SQUARE.playbackUrl,
          postTitle:
            h.HIGHLIGHT_SOCIAL_MEDIA_POSTS.socialMediaPosts.linkedIn.text,
          uploadedToLinkedIn: h?.uploadedToLinkedin?.status === 'COMPLETE',
          aspectRatio: 'square',
          downloadUrl: h?.HIGHLIGHT_EDITED_SQUARE?.playbackUrl,
        })),
    [highlights]
  );

  useEffect(() => {
    if (!section) return;
    scrollToElement(section);
  }, [section]);

  const linkedinPosts = useMemo(
    () =>
      shorts
        .filter(
          (s) =>
            s.INTERESTING_MOMENTS_SHORT_MIXED_EDITED_LANDSCAPE?.aspectRatio ===
            'landscape'
        )
        .map((s, index) => ({
          highlightId: s.highlightId,
          title: s.shortTextData?.thumbnailTitle || `Shorts ${index + 1}`,
          postTitle: s.shortTextData?.linkedinPost || '',
          thumbnailUrl: s.thumbnailUrl,
          playbackUrl:
            s.INTERESTING_MOMENTS_SHORT_MIXED_EDITED_LANDSCAPE.playbackUrl,
          downloadUrl:
            s.INTERESTING_MOMENTS_SHORT_MIXED_EDITED_LANDSCAPE.playbackUrl,
          aspectRatio:
            s.INTERESTING_MOMENTS_SHORT_MIXED_EDITED_LANDSCAPE.aspectRatio,
          presenterPictureUrl: s.shortTextData?.presenterPictureUrl || '',
          uploadedToLinkedIn: s?.uploadedToLinkedin,
        })),
    [shorts]
  );

  const handlePublish = useCallback(
    async (groupId, createdByProcessorId, description, aspectRatio) => {
      if (!linkedinAccountOwnerUserId) {
        return;
      }

      const taskDetails = {
        meetingSeriesId,
        workspaceId: workspace?.workspaceId,
        processorId: 'POST_TO_LINKEDIN',
        groupId,
      };

      const payload = {
        createdByProcessorId,
        description,
        aspectRatio,
        status: 'LOADING',
      };

      const body = {
        taskDetails,
        payload,
        forceRun: true,
        useOptionals: true,
      };

      axios.post(`${PROCESSOR_BASE_URL}/task`, body);

      updateCustomerEngagementStagesApi(
        userId,
        zyncCustomerEngagementStages.PUBLISHED_SOCIAL.stage
      );
    },
    [
      linkedinAccountOwnerUserId,
      meetingSeriesId,
      userId,
      workspace?.workspaceId,
    ]
  );

  useEffect(() => {
    if (!meetingSeriesId) return;
    mixpanel.track('Content Kit: LinkedIn Posts Click', {
      meetingSeriesId,
    });
  }, [meetingSeriesId]);

  return (
    <>
      {breadcrumbs}
      <div className="flex flex-col gap-5 justify-center  w-full mx-auto pl-64 pr-52 relative">
        <img
          src={linkedInIcon}
          alt="linkedin logo"
          className="object-contain w-[30px] self-start pt-5"
        />
        {linkedinPosts.length ? (
          <>
            <div className="flex flex-col gap-5">
              <h2 className="text-blue-dark font-semibold text-xl" id="clips">
                LinkedIn Short Video Clips
              </h2>
              <div className="flex gap-[30px] flex-wrap">
                {linkedinPosts.length > 0 &&
                  linkedinPosts.map((s) => (
                    <LinkedinPost
                      playbackUrl={s.playbackUrl}
                      thumbnailUrl={s.thumbnailUrl}
                      title={s.title}
                      postTitle={s.postTitle}
                      posterUrl={posterUrl}
                      aspectRatio={s.aspectRatio}
                      brandKit={brandKit}
                      key={s.highlightId}
                      files={[]}
                      downloadUrl={s.downloadUrl}
                      saveShortsSocialMediaPost={saveShortsSocialMediaPost}
                      series={series}
                      presenterPictureUrl={s.presenterPictureUrl}
                      shared={shared}
                      highlightId={s.highlightId}
                      workspace={workspace}
                      createdByProcessorId="INTERESTING_MOMENTS_SHORT_MIXED_EDITED_LANDSCAPE"
                      handlePublish={handlePublish}
                      uploadedToLinkedIn={s.uploadedToLinkedIn}
                      linkedinAccountOwnerUserId={linkedinAccountOwnerUserId}
                      refreshContentKit={refreshContentKit}
                      isSoloEpisode={isSoloEpisode}
                    />
                  ))}
              </div>
            </div>
          </>
        ) : null}
        {linkedInHighlights.length || linkedInSoloHighlights.length ? (
          <>
            <div className="flex flex-col gap-5">
              <h2
                className="text-blue-dark font-semibold text-xl mt-4"
                id="posts"
              >
                LinkedIn Posts
              </h2>
              <div className="absolute inset-0 bg-[#3EEDE80D] -z-[9999]" />
              <div className="flex flex-wrap gap-8 w-full pb-8">
                {linkedInHighlights.map((s) => (
                  <LinkedinPost
                    playbackUrl={s.playbackUrl}
                    thumbnailUrl={s.thumbnailUrl}
                    title={s.title}
                    postTitle={s.postTitle}
                    posterUrl={posterUrl}
                    aspectRatio={s.aspectRatio}
                    brandKit={brandKit}
                    key={s.highlightId}
                    files={s.files}
                    downloadUrl={s.downloadUrl}
                    saveHighlightSocialMediaPost={saveHighlightSocialMediaPost}
                    series={series}
                    usePoster={true}
                    shared={shared}
                    highlightId={s.highlightId}
                    workspace={workspace}
                    createdByProcessorId="HIGHLIGHT_EDITED_LANDSCAPE"
                    handlePublish={handlePublish}
                    uploadedToLinkedIn={s.uploadedToLinkedIn}
                    linkedinAccountOwnerUserId={linkedinAccountOwnerUserId}
                    refreshContentKit={refreshContentKit}
                    isSoloEpisode={isSoloEpisode}
                  />
                ))}
                {isSoloEpisode
                  ? linkedInSoloHighlights.map((h) => (
                      <LinkedinPost
                        playbackUrl={h.playbackUrl}
                        thumbnailUrl={h.thumbnailUrl}
                        title={h.title}
                        postTitle={h.postTitle}
                        posterUrl={posterUrl}
                        aspectRatio={h.aspectRatio}
                        brandKit={brandKit}
                        key={h.highlightId}
                        files={[]}
                        downloadUrl={h.downloadUrl}
                        videoUrlOverride={h.thumbnailUrl ? null : h.downloadUrl}
                        usePoster={h.thumbnailUrl ? true : false}
                        saveHighlightSocialMediaPost={
                          saveHighlightSocialMediaPost
                        }
                        highlightId={h.highlightId}
                        shared={shared}
                        handlePublish={handlePublish}
                        uploadedToLinkedIn={h.uploadedToLinkedIn}
                        createdByProcessorId="HIGHLIGHT_EDITED_SQUARE"
                        linkedinAccountOwnerUserId={linkedinAccountOwnerUserId}
                        workspace={workspace}
                        refreshContentKit={refreshContentKit}
                        isSoloEpisode={isSoloEpisode}
                      />
                    ))
                  : null}
              </div>
            </div>
          </>
        ) : null}
        {carouselPdfUrl ? (
          <div className="w-full">
            <h2
              className="text-blue-dark font-semibold text-xl mt-4 mb-6"
              id="carousel"
            >
              LinkedIn Carousel
            </h2>
            <a
              href={carouselPdfUrl}
              target="_blank"
              rel="noopener noreferrer"
              className="flex gap-4 p-4 justify-center items-center h-[100px] w-fit border border-purple bg-purple/5 rounded-md"
            >
              <FontAwesomeIcon
                icon={faFilePdf}
                color="#8B33F7"
                width="36px"
                height="36px"
                className="w-[36px]"
                size="2x"
              />{' '}
              <span className="text-blue-dark text-sm font-medium">
                Click here to access PDF file
              </span>
            </a>
          </div>
        ) : null}
      </div>
    </>
  );
};
