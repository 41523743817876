import React, { useEffect, useRef, useState } from 'react';
import classNames from '../../../helper/classNames';
import { CheckMarkIcon } from '../../../components/icons/CheckMarkIcon';
import {
  Modal,
  ModalBody,
  ModalTitle,
  ModalWindow,
} from '../../../components/Modal';
import CopyToClipboard from 'react-copy-to-clipboard';
import { CopyIcon } from '../../../components/icons/CopyIcon';
import { DownloadIcon } from '../../../components/icons/DownloadIcon';
import { downloadGCSFile } from './PublishMenus';
import ReactPlayer from 'react-player';
import { formatDuration, getHighestQualityDownloadUrl } from './YoutubePosts';
import { Button } from '../../../components/common/Button';
import { PostTextAreaInput } from './PostTextAreaInput';
import { useTrackActivity } from '../../../hooks/track';

export const TiktokModal = ({
  title,
  description = '',
  setShowPublishModal,
  thumbnailUrl,
  playbackUrl,
  alternateVideoUrl,
  aspectRatio,
  videoDownloadUrl,
  videoGCSUrl,
  saveHighlightSocialMediaPost,
  saveShortsSocialMediaPost,
  shared,
  highlightId,
}) => {
  const [editedDescription, setEditedDescription] = useState(description || '');
  const [isSavingPostTitleLoading, setIsSavingPostTitleLoading] =
    useState(false);
  const isDescriptionEdited = editedDescription.trim() !== description.trim();
  const [isDownloadVideoLoading, setIsDownloadVideoLoading] = useState(false);
  const [errorDownloading, setErrorDownloading] = useState(null);

  const track = useTrackActivity();

  useEffect(() => {
    track('Content Kit: TikTok Post Opened', { groupId: highlightId });
  }, [highlightId, track]);

  return (
    <ModalWindow
      size={Modal.size.xl}
      showCancel
      onCancel={() => {
        setShowPublishModal(false);
      }}
    >
      <ModalTitle>{title}</ModalTitle>
      <ModalBody>
        <div className="flex gap-[25px]">
          <div
            className={classNames(
              'flex flex-col gap-5 shrink-0',
              aspectRatio === 'portrait' ? 'flex-1' : 'w-1/4'
            )}
          >
            <div className="flex flex-col gap-2.5">
              <div className="flex justify-between">
                <span className="text-blue-dark text-sm font-medium">
                  Description
                </span>
                <div className="flex gap-1">
                  {!shared && isDescriptionEdited && (
                    <Button
                      padding={Button.padding.NONE}
                      color={Button.colors.PURPLE}
                      border={Button.border.ROUNDED}
                      onClick={async () => {
                        saveHighlightSocialMediaPost
                          ? await saveHighlightSocialMediaPost(
                              setIsSavingPostTitleLoading,
                              editedDescription,
                              highlightId,
                              'tiktok'
                            )
                          : await saveShortsSocialMediaPost(
                              setIsSavingPostTitleLoading,
                              editedDescription,
                              highlightId,
                              'tiktokDescription'
                            );
                        track('Content Kit: TikTok Description Updated');
                      }}
                      state={
                        isSavingPostTitleLoading
                          ? Button.states.LOADING
                          : Button.states.DEFAULT
                      }
                    >
                      <div className="text-xxs h-5 flex justify-center items-center px-2.5">
                        Save
                      </div>
                    </Button>
                  )}
                  <CopyToClipboard text={editedDescription}>
                    <button>
                      <CopyIcon />
                    </button>
                  </CopyToClipboard>
                </div>
              </div>
              <PostTextAreaInput
                resizable={false}
                onChange={(event, value) => {
                  setEditedDescription(value);
                }}
                value={editedDescription}
                rows={6}
              ></PostTextAreaInput>
            </div>
          </div>
          <div
            className={classNames(
              'flex flex-col gap-[15px]',
              aspectRatio === 'portrait' ? 'w-1/4' : 'flex-1'
            )}
          >
            <div className="flex gap-[5px] justify-end">
              {videoDownloadUrl && (
                <a
                  onClick={() => {
                    track('Content Kit: TikTok Video Download Clicked');
                  }}
                  target="_blank"
                  rel="noopener noreferrer"
                  download
                  className="bg-purple text-white text-xxs font-medium p-2 flex gap-[5px] rounded"
                  href={videoDownloadUrl}
                >
                  <DownloadIcon height={12} className="fill-white" />
                  Video
                </a>
              )}
              {videoGCSUrl && (
                <Button
                  padding={Button.padding.NONE}
                  color={Button.colors.PURPLE}
                  onClick={() => {
                    track('Content Kit: TikTok Video Download Clicked');
                    downloadGCSFile(
                      videoGCSUrl,
                      `${title}.mp4`,
                      setIsDownloadVideoLoading,
                      setErrorDownloading
                    );
                  }}
                  state={
                    isDownloadVideoLoading
                      ? Button.states.LOADING
                      : Button.states.DEFAULT
                  }
                >
                  <div className="bg-purple text-white text-xxs font-medium flex gap-[5px] rounded items-center h-min  px-2">
                    <DownloadIcon height={12} className="fill-white" />
                    Video
                  </div>
                </Button>
              )}
              {thumbnailUrl && (
                <button
                  type="button"
                  className="bg-purple text-white text-xxs font-medium p-2 flex gap-[5px] rounded"
                  onClick={() => {
                    track('Content Kit: TikTok Thumbnail Download Clicked');
                    downloadGCSFile(thumbnailUrl, 'thumbnail.png');
                  }}
                >
                  <DownloadIcon height={12} className="fill-white" />
                  Thumbnail
                </button>
              )}
            </div>
            {errorDownloading && (
              <div className="text-red text-xs font-light text-right">
                {errorDownloading}
              </div>
            )}
            {aspectRatio === 'portrait' ? (
              <style>{`.react-player > video { aspect-ratio: 9/16}`}</style>
            ) : null}
            <ReactPlayer
              className={classNames(
                'react-player',
                aspectRatio === 'portrait' ? 'aspect-[9/16]' : 'aspect-video'
              )}
              onPlay={() => {
                track('Content Kit: TikTok Video Played');
              }}
              width={'100%'}
              height="auto"
              url={
                alternateVideoUrl.trim().length > 0
                  ? alternateVideoUrl
                  : playbackUrl
              }
              playing={!!thumbnailUrl}
              controls={true}
              light={thumbnailUrl}
              loop={false}
              volume={1}
              muted={false}
              pip={false}
            />
          </div>
        </div>
      </ModalBody>
    </ModalWindow>
  );
};

export const TikTokPost = ({
  playbackUrl,
  title,
  thumbnailUrl,
  postTitle,
  files,
  handlePublish,
  highlightId,
  uploadedToYoutube,
  saveHighlightSocialMediaPost,
  saveShortsSocialMediaPost,
  aspectRatio = 'landscape',
  createdByProcessorId,
  alternateVideoUrl = '',
  posterUrl,
  brandKit,
  downloadUrl,
  videoUrlOverride,
  shared,
}) => {
  const { primaryColor } = brandKit || {};
  const [showPublishModal, setShowPublishModal] = useState(false);
  const [videoDuration, setVideoDuration] = useState(null);
  const videoDownloadUrl = getHighestQualityDownloadUrl(files);
  const videoGCSUrl = downloadUrl;
  const [showVideo, setShowVideo] = useState(true);
  const videoRef = useRef(null);

  const handleLoadedMetadata = () => {
    if (videoRef.current) {
      setVideoDuration(videoRef.current.duration);
      setShowVideo(false);
    }
  };

  return (
    <>
      {showVideo && (
        <video
          ref={videoRef}
          src={alternateVideoUrl || videoDownloadUrl}
          className="hidden"
          onLoadedMetadata={handleLoadedMetadata}
          onError={() => {
            setShowVideo(false);
          }}
        ></video>
      )}
      {showPublishModal && (
        <TiktokModal
          highlightId={highlightId}
          title={title}
          description={postTitle}
          createdByProcessorId={createdByProcessorId}
          handlePublish={handlePublish}
          setShowPublishModal={setShowPublishModal}
          thumbnailUrl={thumbnailUrl}
          uploadedToYoutube={uploadedToYoutube}
          playbackUrl={playbackUrl}
          alternateVideoUrl={alternateVideoUrl}
          aspectRatio={aspectRatio}
          posterUrl={posterUrl}
          saveHighlightSocialMediaPost={saveHighlightSocialMediaPost}
          saveShortsSocialMediaPost={saveShortsSocialMediaPost}
          videoDownloadUrl={videoDownloadUrl}
          videoGCSUrl={videoGCSUrl}
          shared={shared}
        />
      )}
      <div
        className={classNames(
          'relative',
          aspectRatio === 'portrait' ? 'w-[225px]' : 'w-[320px]'
        )}
      >
        <button
          className={classNames(
            'flex flex-col gap-3',
            !videoUrlOverride
              ? aspectRatio === 'portrait'
                ? 'max-w-[225px]'
                : 'max-w-[320px]'
              : 'w-full'
          )}
          onClick={() => setShowPublishModal(true)}
        >
          <div
            className={classNames(
              'w-full relative flex items-center',
              aspectRatio === 'portrait' && 'h-[400px] bg-white rounded-lg'
            )}
            style={{
              backgroundColor: videoUrlOverride ? 'transparent' : primaryColor,
            }}
          >
            {uploadedToYoutube && (
              <div className="flex min-w-full min-h-full absolute top-0 left-0 bg-black/50"></div>
            )}
            <img
              src={thumbnailUrl}
              alt=""
              className={classNames(
                'object-contain',
                aspectRatio !== 'portrait' && 'rounded-lg'
              )}
            />
            <div className="flex gap-1 absolute bottom-1.5 left-1.5 text-white text-xs font-medium">
              {videoDuration && (
                <div className="flex px-1.5 bg-purple rounded items-center justify-center">
                  {formatDuration(videoDuration)}
                </div>
              )}
              {uploadedToYoutube && (
                <div className="flex px-1.5 bg-purple rounded items-center justify-center gap-1">
                  <div className="flex justify-center items-center w-2 h-2 rounded border-white border">
                    <CheckMarkIcon className="fill-white" />
                  </div>
                  <span>PUBLISHED</span>
                </div>
              )}
            </div>
          </div>
          <div className="text-blue-dark font-semibold text-left">{title}</div>
        </button>
        <video
          className={classNames('absolute inset-0 w-[225px] -z-10')}
          width={225}
          src={videoUrlOverride}
        />
      </div>
    </>
  );
};
