import moment from 'moment-timezone';

const timezoneNames = moment.tz.names();

const filteredTimezoneNames = timezoneNames.filter(
  (timezone) => !timezone.toLowerCase().includes('etc')
);

const sortedTimesoneNames = (timezones, date) =>
  timezones.sort((a, b) => {
    const offsetA = moment(date).tz(a.key).utcOffset();
    const offsetB = moment(date).tz(b.key).utcOffset();

    return offsetA - offsetB;
  });

/* To be used for timezone selectors */
export const timezonesOptions = (date) =>
  sortedTimesoneNames(
    filteredTimezoneNames.map((timezone) => {
      const offset = moment(date).tz(timezone).format('Z');
      const abbreviation = moment(date).tz(timezone).format('z');

      const zoneName = `(GMT${offset}) ${timezone
        .replace(/_/g, ' ')
        .replace(/\//g, ' - ')} (${abbreviation})`;

      return {
        key: timezone,
        title: zoneName,
      };
    }),
    date
  );
