import { useSelector } from 'react-redux';
import mixpanel from 'mixpanel-browser';
import { useCallback } from 'react';

export const useTrackActivity = () => {
  const distinct_id = useSelector((st) => st.auth?.user?.userId);

  const track = useCallback(
    (message, data = {}) => mixpanel.track(message, { distinct_id, ...data }),
    [distinct_id]
  );

  return track;
};
